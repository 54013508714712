<template>
    <div
        class="w-full LoginForm h-full absolute top-0 left-0 flex justify-center items-center"
    >
        <div class="px-4 py-4 max-w-md">
            <div class="bg-white LoginForm__inside">
                <h1 class="text-2xl font-bold bg-primary text-white px-8 py-6">
                    Rezervační systém VirtuEdu
                </h1>
                <div class="px-8 py-6">
                    <p class="text-red-500 mb-4" v-if="error || loginError">
                        {{ error }}
                        {{ loginError }}
                    </p>

                    <button
                        @click="login"
                        :disabled="loading || authUrl === null"
                        class="flex items-center justify-center gap-2 btn btn-primary w-full mt-2"
                    >
                        <img src="../assets/logo.svg" /> Přihlásit se přes MUNI
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PocketBase from "pocketbase"
import Cookies from "js-cookie"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

export default {
    name: "LoginForm",
    props: ["loginError"],
    data() {
        return {
            email: "",
            password: "",
            error: null,
            loading: false,
            authUrl: null,
        }
    },
    methods: {
        async login() {
            window.location = this.authUrl
        },
    },
    async mounted() {
        const redirectUrl = process.env.VUE_APP_POCKETBASE_REDIRECT_URL
        const authMethods = await pb.collection("users").listAuthMethods()

        let providers = authMethods.authProviders.filter(
            (provider) => provider.name === "muni"
        )

        if (providers.length) {
            this.authUrl = providers[0].authUrl + redirectUrl
            Cookies.set("provider", btoa(JSON.stringify(providers[0])), {
                expires: 1,
            })
        } else {
            this.error = "Přihlášení aktuálně není dostupné. Zkuste to později."
        }
    },
}
</script>

<style></style>
